//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClickOutside from 'vue-click-outside';
import {
  faAngleDown,
} from '@fortawesome/pro-regular-svg-icons';

import channelTypesMixin from '@motionelements/core/src/components/mixins/channelTypes.mixin.js';

export default {
  name: 'SearchChannelTypesDropdown',
  mixins: [channelTypesMixin],
  components: {
    ProductIcon: () => import('@motionelements/core/src/components/product/ProductIcon.vue'),
    SearchBarDropdownButton: () => import('@motionelements/core/src/components/ui/search-box/SearchBarDropdownButton.vue'),
  },
  props: {
    value: {
      type: String,
    },
    btnClass: {
      type: String,
      default: 'btn-simple-dark',
    },
    noInvert: {
      type: Boolean,
      default: false,
    },
    onSelect: {
      type: Function,
    },
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      faAngleDown,
      isDropdownOpen: false,
    };
  },
  computed: {
    chosenChannelType() {
      const chosenChannelType = this.getChannelTypeById(this.channelType);
      return chosenChannelType || {
        id: null,
        title: this.$t('search.choose_product_type'),
      };
    },
    channelType: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    setChannelType(channelType) {
      if (this.$route.params.channel) {
        this.$router.push(this.$link(channelType.link).fullPath());
        this.isDropdownOpen = false;
      } else {
        this.channelType = channelType.id;
        this.isDropdownOpen = false;
        this.$emit('onSelect', channelType.id);
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    clickOutside() {
      this.isDropdownOpen = false;
    },
  },
};
